<template>
  <div class="footer-box">
    <div class="footer-wrap container">
      <el-row :gutter="30" type="flex" style="flex-wrap: wrap;" class="footer-flex">
        <el-col :lg="6" :md="8">
        <div class="footer-flex-item">
          <div class="footer-title">Contact Us</div>
          <div class="footer-msg">
            <div class="footer-msg-icon">
              <i class="iconfont icon-head100"></i>
            </div>
            <div class="footer-msg-item">Rm 2201 North Tower, New World Times Centre，2191 Guangyuan East Rd, Tianhe District, Guangzhou, Guangdong Province, China, 510507</div>
          </div>
          <div class="footer-msg">
            <div class="footer-msg-icon">
              <i class="iconfont icon-youjian1"></i>
            </div>
            <div class="footer-msg-item">
              <a href="mailto:ann.wu@apware.cn">ann.wu@apware.cn</a>
            </div>
          </div>
          <div class="footer-msg">
            <div class="footer-msg-icon">
              <i class="iconfont icon-dianhua"></i>
            </div>
            <div class="footer-msg-item">
              <a href="tel:+8613450473308">+8613450473308</a>
            </div>
          </div>
          <div class="footer-msg">
            <div class="footer-msg-icon">
              <i class="iconfont icon-weixin"></i>
            </div>
            <div class="footer-msg-item">
              <a href="tel:+8613450473308">+8613450473308</a>
            </div>
          </div>
          <div class="footer-msg">
            <div class="footer-msg-icon">
              <i class="iconfont icon-whatsapp"></i>
            </div>
            <div class="footer-msg-item">
              <a href="https://wa.me/8618611410380">+8619926065615</a>
            </div>
          </div>
          <div class="footer-msg">
            <div class="footer-msg-icon">
              <i class="iconfont icon-dayinji"></i>
            </div>
            <div class="footer-msg-item">
              <a href="tel:+86(20)37260921">+86(20)37260921</a>
            </div>
          </div>
          <div class="footer-msg">
            <div class="footer-msg-icon">
              <i class="iconfont icon-shijian"></i>
            </div>
            <div class="footer-msg-item">AM 9:00-PM22:00</div>
          </div>
        </div>
        </el-col>
        <el-col :md="4" :sm="12">
        <div class="footer-flex-item">
          <div class="footer-title">Information</div>
          <div @click="gotoContact" style="cursor: pointer;" class="footer-text">Contact</div>
          <div @click="gotoAboutUs" style="cursor: pointer;" class="footer-text">About Us</div>
          <div @click="gotoAgreement('2')" style="cursor: pointer;" class="footer-text">Privacy Policy</div>
        </div>
        </el-col>
        <el-col :md="4" :sm="12">
        <div class="footer-flex-item">
          <div class="footer-title">My Account</div>
          <div @click="gotoQA" style="cursor: pointer;" class="footer-text">FAQ</div>
          <div class="footer-text">My account</div>
        </div>
        </el-col>
        <el-col :lg="10" :md="8">
        <div class="footer-flex-item">
          <div class="footer-title">Latest Tweets</div>
          <div class="footer-text">BBMart - Best China BBA Auto Parts Provider!!</div>
        </div>
        </el-col>
      </el-row>
    </div>
    <img src="../../assets/images/BBmart logo.png" alt="" class="footer-logo">
    <div class="footer-bottom">Copyright ©</div>
    <div class="footer-bottom">
      <a href="https://www.facebook.com/p/BBmart-Auto-Parts-100082812802804/" target="_blank">
        <i class="iconfont icon-mianshulogo"></i>
      </a>
      <a href="https://www.instagram.com/bbmartautoparts/" target="_blank">
        <i class="iconfont icon-instagram-fill big"></i>
      </a>
    </div>
    <div class="footer-link container">
      <div @click="gotoAgreement('1')" class="footer-link-item">Terms of Use</div>
      <div @click="gotoAgreement('2')" class="footer-link-item">Privacy Policy</div>
      <div @click="gotoAgreement('3')" class="footer-link-item">Warranty policy</div>
      <div @click="gotoAgreement('4')" class="footer-link-item">Return policy</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    gotoContact() {
      this.$router.push({
        path: '/contact',
      })
    },
    /** 跳转到关于我们 */
    gotoAboutUs() {
      this.$router.push({
        path: '/aboutUs',
      })
    },
    /** 跳转到Q&A */
    gotoQA() {
      this.$router.push({
        path: '/qAndA',
      })
    },
    /** 跳转到协议 */
    gotoAgreement(type) {
      if(this.$route.query.type !== type) {
        this.$router.push({
          path: '/agreement',
          query: {
            type: type
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-box {
  width: 100%;
  height: auto;
  background: #F4F4F4;
  padding-bottom: 75px;
  .footer-wrap {
    margin: 0 auto;
    border-bottom: 1px solid #CDCDCD;
    padding: 0 15px;
  }
  .footer-flex {
    height: auto;
    padding: 0 0 46px;
    .footer-flex-item {
      padding-top: 50px;
    }
    .footer-title {
      width: 100%;
      line-height: 22px;
      font-size: 22px;
    }
    .footer-msg {
      height: auto;
      display: flex;
      align-items: center;
      margin-top: 28px;
      .footer-msg-icon {
        flex: 0 0 30px;
        text-align: left;
        .iconfont {
          font-size: 19px;
        }
      }
      .footer-msg-item {
        flex: 1;
        line-height: 22px;
        font-size: 15px;
        a {
          color: #333333;
        }
      }
    }
    .footer-text {
      width: 100%;
      line-height: 22px;
      font-size: 15px;
      margin-top: 25px;
      padding-bottom: 8px;
      box-sizing: border-box;
      word-break:break-all;
      word-wrap:break-word;
    }
  }
  .footer-logo {
    width: 183px;
    height: 45px;
    display: block;
    margin: 26px auto 8px;
  }
  .footer-bottom {
    width: 100%;
    line-height: 18px;
    font-size: 18px;
    text-align: center;
    padding-top: 11px;
    a {
      color: #333;
    }
    .iconfont {
      vertical-align: middle;
    }
    .big {
      font-size: 17px;
    }
  }
  .footer-link {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    font-size: 14px;
    padding-top: 35px;
    .footer-link-item {
      width: auto;
      line-height: 20px;
      cursor: pointer;
    }
  }
}

</style>