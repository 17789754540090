<template>
  <header class="header-main_area">
    <!-- 顶部信息 -->
    <div class="header-middle_area">
      <div class="container">
        <el-row :gutter="30" type="flex" align="middle">
          <el-col :lg="6" :md="4">
            <div class="header-logo">
              <router-link to="/home">
                <img src="../../assets/images/BBmart logo.png" alt="" />
              </router-link>
            </div>
          </el-col>
          <el-col :md="6" class="d-none d-lg-block">
            <div class="contact-info">
              <div class="contact-info_icon">
                <i class="iconfont icon-dianhua"></i>
              </div>
              <div class="contact-info_content">
                <p>Need help?</p>
                <a href="tel://86 20 3726 0921/">+8613450473308</a>
              </div>
            </div>
          </el-col>
          <el-col :md="8" class="d-none d-lg-block">
            <div class="hm-form_area">
              <input
                v-model="searchValue"
                type="text"
                class="input"
                placeholder="Search product..."
              />
              <button @click="gotoProductList" class="search_btn" type="submit">
                <i class="el-icon-search"></i>
              </button>
            </div>
          </el-col>
          <el-col :lg="4" :md="6" class="d-none d-lg-block">
            <div class="hm-minicart_area">
              <!-- <div class="item">
                <i class="iconfont icon-shoucang"></i>
                <div class="badge">20</div>
              </div> -->
              <div class="item">
                <router-link to="/cart">
                  <i class="iconfont icon-gouwuche"></i>
                  <div class="badge">{{ cardCount }}</div>
                </router-link>
              </div>
              <div class="item">
                <el-popover placement="bottom" trigger="click">
                  <div @click="changeSignStatus" class="menu-item">
                    {{ userName ? "Log out" : "Sign in" }}
                  </div>
                  <div v-for="item in menuList" :key="item.name">
                    <div @click="goToPath(item)" class="menu-item">
                      {{ item.name }}
                    </div>
                  </div>
                  <i
                    slot="reference"
                    style="cursor: pointer"
                    class="iconfont icon-gerenzhongxin"
                  ></i>
                </el-popover>
                <span class="item-text">{{ userName }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 菜单信息 -->
    <div
      :class="{
        'header-bottom_area': true,
        'bg--tangerine_yellow': yellowType,
      }"
    >
      <div class="container">
        <el-row :gutter="30" type="flex" align="middle">
          <!-- <el-col :md="6" :sm="18" :xs="14">
            <div class="category-menu">
              <div @click="toggleShowChildNav" class="category-heading">
                <h2 class="categories-toggle">
                  <i class="iconfont icon-zhedie2"></i>
                  <span>All Department</span>
                </h2>
              </div>
              <div v-show="showChildNav" class="category-menu-list">
                <ul :class="{'open': openCategoryList}">
                  <router-link to="/shop">
                    <li v-for="(item,index) in departementNavList" :key="index" :title="item.name">{{ item.name }}</li>
                  </router-link>
                </ul>
                <div @click="toggleCategory" class="category-more">
                  <div v-show="openCategoryList">
                    pack up
                    <i class="el-icon-arrow-up"></i>
                  </div>
                  <div v-show="!openCategoryList">
                    more
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-col> -->
          <el-col :md="14" class="d-none d-lg-block">
            <nav class="main_nav">
              <ul>
                <li :class="{ active: currentPageName === 'HOME' }">
                  <router-link to="/home">HOME</router-link>
                </li>
                <li :class="{ active: currentPageName === 'SHOP LIST' }">
                  <router-link to="/shop">PRODUCT LIST</router-link>
                </li>
                <li :class="{ active: currentPageName === 'RFQs' }">
                  <router-link to="/RFQs">RFQs</router-link>
                </li>
                <!-- <li :class="{ active: currentPageName === 'EPC' }">
                  <router-link to="/epc">EPC</router-link>
                </li> -->
                <!-- <li :class="{'active': currentPageName === 'REGISTER'}">
                  <router-link to="/register">REGISTER</router-link>
                </li> -->
              </ul>
            </nav>
          </el-col>
          <el-col :sm="6" :xs="10" class="d-none d-lg-block">
            <div :class="{ 'contact-box': true, dark: !yellowType }">
              <a
                href="https://www.facebook.com/p/BBmart-Auto-Parts-100082812802804/"
                target="_blank"
              >
                <i class="iconfont icon-mianshulogo"></i>
              </a>
              <a
                href="https://www.instagram.com/bbmartautoparts/"
                target="_blank"
              >
                <i class="iconfont icon-instagram-fill big"></i>
              </a>
              <a href="mailto:ann.wu@apware.cn" title="ann.wu@apware.cn">
                <i slot="reference" class="iconfont icon-youjian1 big"></i>
              </a>
              <!-- <el-popover placement="top" trigger="hover" content="whatsapp：+8619926065615">
                <i slot="reference" class="iconfont icon-whatsapp small"></i>
              </el-popover> -->
              <a
                href="https://wa.me/8618611410380"
                title="whatsapp：+8619926065615"
              >
                <i slot="reference" class="iconfont icon-whatsapp small"></i>
              </a>
              <a
                href="https://www.youtube.com/@bbmartautoparts5963/featured"
                target="_blank"
              >
                <i class="iconfont icon-Youtube-fill big"></i>
              </a>
            </div>
          </el-col>
          <el-col :sm="6" :xs="10" class="d-block d-lg-none">
            <div class="mobile-menu_area">
              <ul>
                <li class="minicart-area">
                  <router-link to="/cart">
                    <i class="iconfont icon-gouwuche"></i>
                    <div class="item-count">{{ cardCount }}</div>
                  </router-link>
                </li>
                <li @click="toggleSideNav">
                  <i class="iconfont icon-31liebiao"></i>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 下拉菜单 -->
    <div :class="{ 'mobile-menu_wrapper': true, open: showSideNav }">
      <div class="offcanvas-menu-inner">
        <div class="container">
          <div @click="toggleSideNav" class="btn-close">
            <i class="el-icon-close"></i>
          </div>
          <div class="offcanvas-inner_search">
            <div class="hm-searchbox">
              <input
                v-model="searchValue"
                type="text"
                placeholder="Search product..."
              />
              <button @click="gotoProductList" class="search_btn">
                <i class="el-icon-search"></i>
              </button>
            </div>
          </div>
          <nav class="offcanvas-navigation">
            <ul class="mobile-menu">
              <li v-for="item in mobileMenuList" :key="item.name">
                <router-link :to="item.path">{{ item.name }}</router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <!-- 滚动后的菜单 -->
    <div v-show="showFixNav" class="header-bottom_area header-sticky">
      <div class="container">
        <el-row :gutter="30" type="flex" align="middle">
          <el-col :md="6" :sm="18" :xs="14">
            <div class="header-logo">
              <router-link to="/home">
                <img src="../../assets/images/BBmart logo.png" alt="" />
              </router-link>
            </div>
          </el-col>
          <el-col :md="14" class="d-none d-lg-block">
            <nav class="main_nav">
              <ul>
                <li :class="{ active: currentPageName === 'HOME' }">
                  <router-link to="/home">HOME</router-link>
                </li>
                <li :class="{ active: currentPageName === 'SHOP LIST' }">
                  <router-link to="/shop">PRODUCT LIST</router-link>
                </li>
                <li :class="{ active: currentPageName === 'RFQs' }">
                  <router-link to="/RFQs">RFQs</router-link>
                </li>
                <!-- <li :class="{ active: currentPageName === 'EPC' }">
                  <router-link to="/epc">EPC</router-link>
                </li> -->
                <!-- <li :class="{'active': currentPageName === 'SINGLE PRODUCT'}">
                  <router-link to="/shopDetail">SINGLE PRODUCT</router-link>
                </li> -->
                <!-- <li :class="{'active': currentPageName === 'LOGIN REGISTER'}">LOGIN REGISTER</li> -->
              </ul>
            </nav>
          </el-col>
          <el-col :sm="6" :xs="10" class="d-none d-lg-block">
            <div class="hm-minicart_area">
              <!-- <div class="item">
                <i class="iconfont icon-shoucang"></i>
                <div class="badge">20</div>
              </div> -->
              <div class="item">
                <router-link to="/cart">
                  <i class="iconfont icon-gouwuche"></i>
                  <div class="badge">{{ cardCount }}</div>
                </router-link>
              </div>
              <div class="item">
                <el-popover placement="bottom" trigger="click">
                  <div @click="changeSignStatus" class="menu-item">
                    {{ userName ? "Log out" : "Sign in" }}
                  </div>
                  <div v-for="item in menuList" :key="item.name">
                    <div @click="goToPath(item)" class="menu-item">
                      {{ item.name }}
                    </div>
                  </div>
                  <i
                    slot="reference"
                    style="cursor: pointer"
                    class="iconfont icon-gerenzhongxin"
                  ></i>
                </el-popover>
                <span class="item-text">{{ userName }}</span>
              </div>
            </div>
          </el-col>
          <el-col :sm="6" :xs="10" class="d-block d-lg-none">
            <div class="mobile-menu_area">
              <ul>
                <li class="minicart-area">
                  <router-link to="/cart">
                    <i class="iconfont icon-gouwuche"></i>
                    <div class="item-count">{{ cardCount }}</div>
                  </router-link>
                </li>
                <li @click="toggleSideNav">
                  <i class="iconfont icon-31liebiao"></i>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 回到顶部 -->
      <div @click="byScrollTop" class="back-box">
        <i class="el-icon-arrow-up"></i>
      </div>
    </div>
    <!-- 联系方式 -->
    <div class="contact-fixed d-block d-lg-none">
      <div class="contact-fixed-item">
        <a
          href="https://www.facebook.com/p/BBmart-Auto-Parts-100082812802804/"
          target="_blank"
        >
          <i class="iconfont icon-mianshulogo"></i>
        </a>
      </div>
      <div class="contact-fixed-item">
        <a href="https://www.instagram.com/bbmartautoparts/" target="_blank">
          <i class="iconfont icon-instagram-fill"></i>
        </a>
      </div>
      <div class="contact-fixed-item">
        <a href="mailto:ann.wu@apware.cn" title="ann.wu@apware.cn">
          <i class="iconfont icon-youjian1"></i>
        </a>
        <!-- <el-popover placement="left" trigger="click" content="ann.wu@apware.cn">
          <i slot="reference" class="iconfont icon-youjian1"></i>
        </el-popover> -->
      </div>
      <div class="contact-fixed-item">
        <!-- <el-popover placement="left" trigger="click" content="whatsapp：+8619926065615">
          <i slot="reference" class="iconfont icon-whatsapp"></i>
        </el-popover> -->
        <a href="https://wa.me/8618611410380" title="whatsapp：+8619926065615">
          <i class="iconfont icon-whatsapp"></i>
        </a>
      </div>
      <div class="contact-fixed-item">
        <a
          href="https://www.youtube.com/@bbmartautoparts5963/featured"
          target="_blank"
        >
          <i class="iconfont icon-Youtube-fill"></i>
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    currentPageName: {
      type: String,
      default: "HOME",
    },
    yellowType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userName: "",
      searchValue: "",
      cartCount: 0,
      showChildNav: false,
      showSideNav: false,
      showFixNav: false,
      departementNavList: [
        {
          name: "AIP FILTER",
          category: "Air Filters",
          children: [],
        },
        {
          name: "OIL FILTER",
          category: "Oil Filters",
          children: [],
        },
        {
          name: "IGNTION COIL",
          category: "Ignition Coils",
          children: [],
        },
        {
          name: "FUEL PUMP",
          children: [],
        },
        {
          name: "BRAKE PAD",
          children: [],
        },
        {
          name: "ABS SENSOR",
          children: [],
        },
        {
          name: "DRIVE SHAFT",
          children: [],
        },
        {
          name: "FOR PORSCHE",
          children: [],
        },
        {
          name: "C.V.JOINT",
          children: [],
        },
        {
          name: "WINDSHIELE WASHER PUMP",
          children: [],
        },
        {
          name: "STEERING RACK",
          children: [],
        },
        {
          name: "CLUTCH DISC",
          children: [],
        },
        {
          name: "SHOCK ABSORBER",
          children: [],
        },
        {
          name: "CONTORL ARM",
          children: [],
        },
        {
          name: "BALL JOINT",
          children: [],
        },
        {
          name: "STABILIZER LINK",
          children: [],
        },
        {
          name: "TIE ROD END",
          children: [],
        },
        {
          name: "RACKEND",
          children: [],
        },
        {
          name: "STRUT MOUNT",
          children: [],
        },
        {
          name: "ENGINE MOUNTING",
          children: [],
        },
        {
          name: "SUSPENSION BUSHING",
          children: [],
        },
        {
          name: "TAILGATE GAS STRUT",
          children: [],
        },
        {
          name: "WHEEL HUB BEARING",
          children: [],
        },
        {
          name: "RELEASE BEARING",
          children: [],
        },
        {
          name: "BEARING",
          children: [],
        },
        {
          name: "ALTERNATOR",
          children: [],
        },
        {
          name: "BRAKE DISC",
          children: [],
        },
        {
          name: "IGNTION CABLES",
          children: [],
        },
        {
          name: "BUMPER",
          children: [],
        },
        {
          name: "UNIVERSAL",
          children: [],
        },
        {
          name: "PROPSHAFT JOINT",
          children: [],
        },
        {
          name: "FUEL FILTER",
          children: [],
        },
        {
          name: "HEAER CORE",
          children: [],
        },
        {
          name: "HEAT EXCHANGER",
          children: [],
        },
        {
          name: "SHOCK BELLOW",
          children: [],
        },
        {
          name: "WHEEL CYLINDER",
          children: [],
        },
        {
          name: "WATER PUMP",
          children: [],
        },
      ],
      openCategoryList: false,
      showBackTop: false,
      menuList: [
        {
          name: "Register",
          path: "/register",
        },
        // {
        //   name: "Order",
        //   path: "/order",
        // },
      ],
      mobileMenuList: [
        {
          name: "HOME",
          path: "/home",
        },
        {
          name: "PRODUCT LIST",
          path: "/shop",
        },
        {
          name: 'RFQs',
          path: '/RFQs'
        },
        // {
        //   name: 'EPC',
        //   path: '/epc'
        // },
        {
          name: "SIGN IN",
          path: "/login",
        },
        {
          name: "REGISTER",
          path: "/register",
        },
        {
          name: "ORDER",
          path: "/order",
        },
      ],
    };
  },
  computed: {
    cardCount() {
      return this.$store.state.cartCount;
    },
  },
  created() {
    this.showChildNav = this.yellowType;
    this.byScrollTop();
    /* if (window.localStorage.getItem("cartIdArr")) {
        let arr = window.localStorage.getItem("cartIdArr").split(",");
        this.$store.commit("setCartCount", arr.length);
      } */
    this.userName = window.sessionStorage.getItem("userName");
  },
  mounted() {
    window.addEventListener("scroll", this.showNav);
    window.addEventListener("resize", this.resizeWindow);
    this.resizeWindow();
  },
  destroyed() {
    window.removeEventListener("scroll", this.showNav);
    window.removeEventListener("resize", this.resizeWindow);
  },
  methods: {
    changeSignStatus() {
      if (this.userName) {
        window.sessionStorage.removeItem("accessToken");
        window.sessionStorage.removeItem("userName");
        window.sessionStorage.removeItem("agencyLevel");
        this.saveUserInfo();
      }
      if (this.$route.path !== "/login") {
        this.$router.push({
          path: "/login",
        });
      }
    },
    /** 跳转 */
    goToPath(item) {
      this.$router.push({
        path: item.path,
      });
    },
    gotoProductList() {
      if (
        this.searchValue !== "" &&
        this.searchValue !== undefined &&
        this.searchValue !== null
      ) {
        this.$router.push({
          path: "/shop",
          query: {
            key: this.searchValue,
          },
        });
      } else {
        this.$router.push({
          path: "/shop",
        });
      }
      if (this.currentPageName === "SHOP LIST") {
        this.$emit("reload", this.searchValue);
      }
    },
    /** 监听窗口变化 */
    resizeWindow() {
      if (this.yellowType) {
        if (window.innerWidth < 992) {
          this.showChildNav = false;
          this.openCategoryList = false;
        } else {
          this.showChildNav = true;
        }
      }
    },
    /** 回到顶部 */
    byScrollTop() {
      document.documentElement.scrollTop = 0;
      window.pageYOffset = 0; // 兼容ios
      document.body.scrollTop = 0; // 兼容低版本ie
    },
    toggleCategory() {
      this.openCategoryList = !this.openCategoryList;
    },
    /** 控制吸顶菜单显隐 */
    showNav() {
      let scrollTop =
        window.pageYOffset ||
        document.body.scrollTop ||
        document.documentElement.scrollTop;
      if (scrollTop > 174) {
        this.showFixNav = true;
      } else {
        this.showFixNav = false;
      }
      if (scrollTop > 400) {
        this.showBackTop = true;
      } else {
        this.showBackTop = false;
      }
    },
    /** 切换侧边菜单 */
    toggleSideNav() {
      this.showSideNav = !this.showSideNav;
    },
    /** 切换子菜单 */
    toggleShowChildNav() {
      this.showChildNav = !this.showChildNav;
      if (!this.showChildNav) {
        this.openCategoryList = false;
      }
    },
    ...mapActions(["saveUserInfo"]),
  },
};
</script>

<style lang="scss" scoped>
a {
  color: unset;
}

.hm-minicart_area {
  text-align: right;
  padding-right: 20px;

  a {
    color: unset;
  }

  .item {
    color: #000000;
    display: inline-block;
    position: relative;
    margin-left: 24px;

    &:first-of-type {
      margin-left: 0;
    }

    .iconfont {
      font-size: 21px;
    }

    .badge {
      width: 25px;
      line-height: 25px;
      height: 25px;
      color: #fff;
      text-align: center;
      font-size: 12px;
      border-radius: 50%;
      background: #d54029;
      position: absolute;
      right: -18px;
      top: -18px;
    }

    .item-text {
      font-size: 12px;
      line-height: 25px;
      margin-left: 5px;
    }
  }
}

.header-middle_area {
  padding: 30px 0;

  .container {
    .header-logo {
      @media only screen and (max-width: 991px) {
        text-align: center;
      }

      img {
        width: 100%;
        max-width: 154px;
        height: auto;
      }
    }

    .contact-info {
      display: flex;

      .contact-info_icon {
        width: 50px;
        line-height: 50px;
        text-align: center;

        .icon-dianhua {
          font-size: 40px;
          color: #fed100;
          vertical-align: middle;
        }
      }

      .contact-info_content {
        flex: 1;
        line-height: 24px;
        font-size: 15px;
        color: #7b7b7b;

        a {
          line-height: 24px;
          font-size: 15px;
          color: #7b7b7b;
        }
      }
    }

    .hm-form_area {
      width: 100%;
      height: 50px;
      position: relative;

      .input {
        border: 2px solid #fdb828;
        border-radius: 30px;
        font-size: 14px;
        height: 50px;
        width: 100%;
        padding: 0 60px 0 33px;
        outline-style: none;
      }

      .search_btn {
        border: 0;
        background: none;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);

        .el-icon-search {
          font-size: 20px;
        }
      }
    }
  }
}

.header-bottom_area {
  &.header-sticky {
    transition: all 300ms ease-in 0s;
    z-index: 1003;
    padding: 20px 0;
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    background: #fff;

    .header-logo {
      img {
        width: 154px;
      }
    }
  }

  .category-menu {
    position: relative;

    .category-heading {
      line-height: 1;
      background: #fed100;
      position: relative;
      cursor: pointer;
      padding: 22px 0;
      border-radius: 25px 25px 0 0;

      .categories-toggle {
        font-size: 16px;
        padding-left: 40px;
      }
    }

    .category-menu-list {
      background: #ffffff;
      border: 2px solid #fed100;
      border-radius: 0 0 25px 25px;
      padding: 0 20px;
      position: absolute;
      left: 0;
      top: calc(100% - 1px);
      display: block;
      width: 100%;
      z-index: 1002;

      ul {
        height: 418px;
        overflow: hidden;

        &.open {
          height: auto;
          overflow: auto;
        }

        li {
          font-size: 15px;
          font-weight: 600;
          line-height: 24px;
          position: relative;
          border-bottom: 1px solid #ebebeb;
          padding: 8.4px 0;
          cursor: pointer;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;

          &:hover {
            color: #fed100;
          }
        }
      }

      .category-more {
        line-height: 24px;
        font-size: 15px;
        text-align: center;
        color: #999;
        padding: 8.4px 0;
        cursor: pointer;
      }
    }
  }

  .main_nav {
    li {
      display: inline-block;
      padding-right: 30px;
      line-height: 60px;
      cursor: pointer;

      &.active {
        color: #fed100;
        font-weight: bold;
      }

      &:hover {
        color: #fed100;
      }
    }
  }

  .contact-box {
    line-height: 36px;
    color: #fff;
    display: flex;
    justify-content: flex-end;

    .iconfont {
      font-size: 30px;
      margin-left: 5px;
      vertical-align: middle;

      &.big {
        font-size: 32px;
      }

      &.small {
        font-size: 28px;
      }

      &.icon-mianshulogo {
        color: #3c5a99;
      }

      &.icon-instagram-fill {
        color: #e4007f;
      }

      &.icon-whatsapp {
        color: #3ac34c;
      }

      &.icon-Youtube-fill {
        color: #d54029;
      }
    }

    &.dark {
      color: #fed100;
    }
  }

  .mobile-menu_area {
    // text-align: right;
    li {
      display: inline-block;
      cursor: pointer;

      .iconfont {
        font-size: 20px;
        font-weight: bold;
      }
    }

    .minicart-area {
      background-color: #fed100;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 25%;
      text-align: center;
      position: relative;
      margin-right: 15px;

      .icon-gouwuche {
        font-weight: normal;
        color: #fff;
      }

      .item-count {
        background: #d54029;
        color: #ffffff;
        position: absolute;
        top: -15px;
        right: -5px;
        width: 25px;
        height: 25px;
        line-height: 23px;
        border-radius: 50%;
        font-size: 14px;
      }
    }
  }

  &.bg--tangerine_yellow {
    background-color: #fed100 !important;

    .category-menu {
      .category-heading {
        background-color: #333333;
        border-radius: 0;

        .categories-toggle {
          color: #fff;
        }
      }

      .category-menu-list {
        border-top: 0;
        border-radius: 0;
      }
    }

    .main_nav {
      li {
        &.active {
          color: #fff;
        }

        &:hover {
          color: #fff;
        }
      }
    }
  }
}

.mobile-menu_wrapper {
  &.open {
    .offcanvas-menu-inner {
      transition: all 0.3s ease-in;
      left: auto;
      right: 0;
      visibility: visible;
      padding: 105px 0 0;
    }
  }

  .offcanvas-menu-inner {
    position: fixed;
    top: 0;
    right: -285px;
    width: 285px;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    z-index: 1004;
    visibility: hidden;
    transition: all 0.3s ease-in;

    .container {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }

    .btn-close {
      position: absolute;
      top: 0;
      left: -60px;
      background: #333333;
      width: 60px;
      height: 60px;
      line-height: 63px;
      text-align: center;
      color: #ffffff;
      z-index: 10;
      font-size: 24px;
      cursor: pointer;

      i {
        transform: rotate(0);
        transition: all 0.3s ease-in;
      }

      &:hover {
        background-color: #fed100;

        i {
          transform: rotate(90deg);
          color: #fff;
          transition: all 0.3s ease-in;
        }
      }
    }

    .offcanvas-inner_search {
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
      width: calc(100% - 0px);
      padding: 10px;
      background-color: #ebebeb;

      .hm-searchbox {
        position: relative;

        input {
          background-color: #ebebeb;
          border: 0;
          height: 40px;
          line-height: 40px;
          width: 100%;
          padding: 0 52px 0 15px;
          outline: none;
        }

        .search_btn {
          background: transparent;
          color: #333333;
          position: absolute;
          top: 5px;
          right: 20px;
          border: 0;
          font-size: 24px;
        }
      }
    }

    .mobile-menu {
      overflow-y: auto;
      min-height: 165px;
      margin-right: -25px;
      padding-right: 25px;

      li {
        border-bottom: 1px solid #ebebeb;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        display: block;
        padding: 10px 0;
        cursor: pointer;

        &:last-of-type {
          border-bottom: 0;
        }
      }
    }
  }
}

.back-box {
  width: 50px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background: #333;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  right: 15px;
  bottom: 50px;

  &:hover {
    background: #fed100;
  }
}

.contact-fixed {
  width: 50px;
  height: auto;
  position: fixed;
  right: 15px;
  bottom: 105px;
  z-index: 99;

  .contact-fixed-item {
    width: 50px;
    height: 50px;
    line-height: 48px;
    text-align: center;
    color: #7b7b7b;
    margin-bottom: 5px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #f4f4f4;
    box-sizing: border-box;

    .iconfont {
      font-size: 30px;
      vertical-align: middle;
    }
  }
}

.menu-item {
  width: auto;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #fdb828;
  }
}
</style>
